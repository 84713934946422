<div *ngIf="!userDetails">
  <div class="flex justify-between h-36">
    <h1 class="text-[25px] font-bold self-center">User Details loading</h1>
  </div>
</div>
<div *ngIf="userDetails">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">Editing {{userDetails.firstName + ' ' + userDetails.lastName}}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-4 gap-x-10 mt-5" [formGroup]="editForm">
      <!-- <div class="col-span-1">
        <h3 class="font-bold text-[16px] mb-2">Work Email</h3>
        <input formControlName="emailName" type="text" class="border-b border-black focus:outline-none w-full">
        <mat-error *ngIf="emailName.touched && emailName.errors?.email" class="text-sm text-red-700 pt-4">
          Input a valid email address.
        </mat-error>
      </div> -->
      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <h3 class="font-bold text-[16px] required">Work Email</h3>
          <input matInput formControlName="emailName" type="text"
            class="border-b border-black focus:outline-none w-full">
          <mat-error *ngIf="emailName.touched && emailName.errors?.email" class="text-sm text-red-700 pt-4">
            Input a valid email address.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-span-1">
        <label>Work Title</label>
        <p>{{userDetails.jobTitle}}</p>
      </div>
      <div class="col-span-1">
        <h3 class="font-bold text-[16px]">Account Created</h3>
        <p>{{userDetails.createdOn | date: 'M/d/yyyy' }}</p>
      </div>
      <div class="col-span-1">
        <h3 class="font-bold text-[16px]">Last Login</h3>
        <p>{{userDetails.lastLogin ? (userDetails.lastLogin | date: 'M/d/yyyy') : '' }}</p>
      </div>
      <div class="col-span-1">
        <drdp-select-status [initialStatusId]="statusTypeId.value" (status)="handleChosenAccountStatus($event)"
          [required]="true">
        </drdp-select-status>
      </div>
      <div class="col-span-1">
        <h3 class="font-bold text-[16px]">Account Status Modified Date</h3>
        <p>{{userDetails.modifiedOn | date: 'M/d/yyyy'}}</p>
      </div>
      <div class="col-span-1">
        <h3 class="font-bold text-[16px]">Account Status Modified By</h3>
        <p>{{userDetails.modifiedName}}</p>
      </div>
      <div class="col-span-1">
        <h3 class="font-bold text-[16px]">Pasword Status</h3>
        <p>
          Last Changed on {{userDetails.passwordChangedOn ?
          (userDetails.passwordChangedOn | date: 'M/d/yyyy') : ''}}
        </p>
      </div>
      <div class="col-span-1">
        <drdp-select-state [initialStateId]="stateId.value" (state)="handleChosenState($event)" [required]="true">
        </drdp-select-state>
      </div>
      <div class="col-span-1">
        <drdp-select-agency [initialAgencyId]="agencyId.value" (agency)="handleChosenAgency($event)"
          [stateId]="stateId.value">
        </drdp-select-agency>
      </div>
      <div class="col-span-1">
        <drdp-select-site [initialSiteIds]="siteIds.value" (site)="handleChosenSites($event)"
          [agencyId]="agencyId.value" [multiSelect]="true" [multiSelectInitVals]="true"
          [required]="agencyId.value" [isValid]="siteIds.valid">
        </drdp-select-site>
      </div>
      <div class="col-span-1">
        <drdp-select-county [initialCountyIds]="countyIds.value" (county)="handleChosenCounties($event)"
          [stateId]="stateId.value" [multiSelect]="true" [multiSelectInitVals]="true">
        </drdp-select-county>
      </div>
      <div class="col-span-1">
        <drdp-select-grantee [initialGranteeId]="granteeAgencyId.value" 
          (agency)="handleChosenGranteeAgency($event)" [stateId]="stateId.value">
        </drdp-select-grantee>
      </div>
      <div class="col-span-1">
        <drdp-select-contractor [initialContractorId]="contractorAgencyId.value" 
          (agency)="handleChosenContractorAgency($event)" [stateId]="stateId.value">
        </drdp-select-contractor>
      </div>
    </form>
    <div class="col-span-2 items-center w-[100%]">
      <label class="required">Roles</label>
      <div class="grid grid-cols-2 gap-4 gap-x-10 mt-2 pb-3">
        <div class="col-span-1 items-center w-[100%]">
          <label>User Roles</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.UserRoles">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())" [value]="role.id.toString()" (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>Enrollment User</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.EnrollmentUser">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())"  [value]="role.id.toString()" (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>Administrator Roles</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.AdministratorRoles">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())"  [value]="role.id.toString()" (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>State Users</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.StateUsers">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())"  [value]="role.id.toString()" (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>Lead Admin Roles</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.LeadAdminRoles">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())" [value]="role.id.toString()"  (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>Power User Roles</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.PowerUserRoles">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm.get(role.id.toString())" [value]="role.id.toString()"  (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label>System Power User</label>
          <div class="flex flex-col">
            <div *ngFor="let role of roleList">
              <div *ngIf="role.roleGroupId === roleGroup.SystemPowerUser">
                <p>
                  <mat-checkbox #roleCheckbox [formControl]="roleForm?.get(role.id.toString())"  [value]="role.id.toString()" (change)="handleChosenRoles(role.id)"
                    color="primary">
                  </mat-checkbox>
                  {{role.roleName}}
                  <i *ngIf="role.tooltipInformation" class="uil uil-info-circle text-xs" [matTooltip]="role.tooltipInformation"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row justify-end">
    <drdp-btn mat-button mat-dialog-close [color]="'cancel'">Cancel</drdp-btn>
    <drdp-btn class="mx-3" [color]="'red'" (click)="undoChanges()">Undo Changes</drdp-btn>
    <drdp-btn [disabled]="editForm.invalid" (click)="submit()">Save Edit</drdp-btn>
  </div>
</div>
