import { Component, OnInit } from '@angular/core';
import { take, pipe } from 'rxjs';
import { ReportService } from '@core/services/report.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { SelectType } from '@core/enums/select';
import { IReportAction, IReportType } from '@core/interfaces/ireport-type';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { ISchoolYear } from '@core/interfaces/iratingperiod';

@Component({
  selector: 'drdp-report-usage',
  templateUrl: './report-usage.component.html',
  styleUrls: ['./report-usage.component.scss']
})

export class ReportUsageComponent implements OnInit {
  user = this.authService.getCurrentUser();
  ratingCompletionSearch: FormGroup | any;
  clearDropdowns: boolean = false;
  allAgencies: boolean = false;
  btnClass: string = 'lg:col-span-3';

  get reportTypeIds() { return this.ratingCompletionSearch.get('reportTypeIds'); }
  get stateId() { return this.ratingCompletionSearch.get('stateId'); }
  get schoolYearId() { return this.ratingCompletionSearch.get('schoolYearId'); }
  get agencyId() { return this.ratingCompletionSearch.get('agencyId'); }
  get globalRatingPeriodIds() { return this.ratingCompletionSearch.get('globalRatingPeriodIds'); }
  get ageGradeInstrumentIds() { return this.ratingCompletionSearch.get('ageGradeInstrumentIds'); }
  get reportActionIds() { return this.ratingCompletionSearch.get('reportActionIds'); }
  public get selectType() { return SelectType; }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.ratingCompletionSearch = this.fb.group({
      reportTypeIds: [null, Validators.required],
      stateId: [this.authService.getUserStateId(), Validators.required],
      agencyId: [this.authService.getCurrentUser().agencyId ?? null],
      globalRatingPeriodIds: [null],
      schoolYearId: [null],
      ageGradeInstrumentIds: [null],
      reportActionIds: [null]
    });
  }

  handleDownload(): void {
    let payload = this.ratingCompletionSearch.value;
    this.reportService.getReportUsage(payload).pipe(take(1)).subscribe((res: any) => {
        const file = new Blob([res], { type: 'text/csv' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        let fileName = 'ReportUsage';
        link.download = `${fileName.replace(/ /g, '_')}.csv`;
        link.click();
    });
  }
  
  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.ReportType:
        let reportTypeIds = event?.map((reportType: IReportType) => reportType.id)
        this.reportTypeIds.setValue(reportTypeIds.toString());
        break;
      case SelectType.States:
        this.stateId.setValue(event?.id);
        this.agencyId.setValue(null);
        this.globalRatingPeriodIds.setValue(null);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        break;
      case SelectType.RatingPeriods:
        let ratingPeriodIds =  this.agencyId.value 
          ? event?.map((ratingPeriod: any) => ratingPeriod.globalRatingPeriodId)
          : event?.map((globalRatingPeriod: any) => globalRatingPeriod.id);
        this.globalRatingPeriodIds.setValue(ratingPeriodIds.length === 0 ? null : ratingPeriodIds.toString());
        break;
    case SelectType.SchoolYear:
        this.schoolYearId.setValue(event?.id);
        break;
    case SelectType.AgeInstrument:
        let ageGradeInstrumentIds = event?.map((ageInstrument: IAgeGradeInstrument) => ageInstrument.id);
        this.ageGradeInstrumentIds.setValue(ageGradeInstrumentIds.length === 0 ? null : ageGradeInstrumentIds.toString());
        break;
    case SelectType.ReportAction:
        let reportActionIds = event?.map((reportAction: IReportAction) => reportAction.id);
        this.reportActionIds.setValue(reportActionIds.length === 0 ? null : reportActionIds.toString());
        break;
    }
  }

  handleGlobal(): void {
    this.allAgencies = !this.allAgencies;
    this.btnClass = this.allAgencies ? 'lg:col-span-4' :'lg:col-span-3';
    this.agencyId.setValue(null);
  }

}
