import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IStateDashboardConfiguration, IStateDashboardTile } from '@core/interfaces/istate-dashboard';
import { LookupService } from '@core/services/lookup.service';
import { StateService } from '@core/services/state.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-state-dashboard',
  templateUrl: './state-dashboard.component.html',
  styleUrls: ['./state-dashboard.component.scss']
})
export class StateDashboardComponent implements OnInit {
  @Input() stateId?: number;
  dashboardForm: FormGroup | any;
  dashboardTiles: IStateDashboardTile[] = [];
  configurations: IStateDashboardConfiguration[] = [];
  isSubmitting: boolean = false;

  private fb = inject(FormBuilder);
  private lookupService = inject(LookupService);
  private stateService = inject(StateService);
  private router = inject(Router);


  ngOnInit(): void {
    this.lookupService.getStateDashboardTiles()
      .pipe(take(1))
      .subscribe((tiles: IStateDashboardTile[]) => {
        this.dashboardTiles = tiles;
      });

    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stateId']) this.initializeValues();
  }

  initializeForm(): void {
    this.dashboardForm = this.fb.group({
      enrollment: [false],
      ageGroup: [false],
      childByRace: [false],
      rating: [false],
      programByAgeGroup: [false],
      homeLanguage: [false],
      childByIep: [false],
      ageBasedOnDob: [false],
    });
  }

  initializeValues(): void {
    if (this.stateId) {
      this.stateService.getDashboardConfigurations(this.stateId)
        .pipe(take(1))
        .subscribe((configs: IStateDashboardConfiguration[]) => {
          if (configs) {
            this.configurations = configs;
            this.configurations.forEach(config => {
              this.dashboardForm.controls[config.controlName].setValue(config.isActive);
            });
          }
        });
    }
  }

  updateConfigurations(): void {
    Object.keys(this.dashboardForm.controls).forEach(key => {
      const config = this.configurations.find(c => c.controlName === key);

      if (config) {
        config.isActive = this.dashboardForm.controls[key].value;
      } else if (this.dashboardForm.controls[key].value) {
        this.configurations.push({
          id: 0,
          stateId: this.stateId ?? 0,
          controlName: key,
          isActive: this.dashboardForm.controls[key].value
        });
      }
    });
  }

  onSubmit(): void {
    this.isSubmitting = true;
    if (this.dashboardForm.value) {
      this.updateConfigurations();

      this.stateService.updateDashboardConfigurations(this.configurations, this.stateId ?? 0)
        .pipe(take(1)).subscribe((res: boolean) => {
          if (res) {
            this.initializeValues();
            this.isSubmitting = false;
          }
        });
    }
  }

  onCancel(): void {
    this.router.navigate(['config/states']);
  }
}
