import { Injectable } from '@angular/core';
import { IAdaptationType } from '@core/interfaces/iadaptation-type';
import {
  IAgeGradeEnrollmentProgramFilter,
  IAgeGradeInstrument,
} from '@core/interfaces/iage-grade-instrument';
import { ICounty } from '@core/interfaces/icounty';
import { IDemographicInformation } from '@core/interfaces/idemographic-information';
import { IDisability } from '@core/interfaces/idisability';
import { IDistrictOfLiability } from '@core/interfaces/idistrict-of-liability';
import { IEnrollmentProgramType } from '@core/interfaces/ienrollment-program-type';
import { IEthnicity } from '@core/interfaces/iethnicity';
import { IGender } from '@core/interfaces/igender';
import { IIepPlan } from '@core/interfaces/iiep-plan';
import { ILanguage } from '@core/interfaces/ilanguage';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { IInitialAssessmentToolType, IOutcome, ISelectBoolean } from '@core/interfaces/iselectable';
import { ITuitionFee } from '@core/interfaces/ituition-fee';
import { IStatusType } from '@core/interfaces/iuser';
import { IVendor } from '@core/interfaces/ivendor';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IClassExitReason } from '@core/interfaces/iclasses';
import { IHeadStart, IStatePreschool } from '@core/interfaces/ihead-start';
import { IRegionGroup } from '@core/interfaces/iregion-group';
import { ISelpa } from '@core/interfaces/iselpa';
import { ICompanionDocument } from '@core/interfaces/icompanion-document';
import { RequestAction, StudentRequestReason } from '@core/enums/student-request-reason';
import { IReportAction, IReportType } from '@core/interfaces/ireport-type';
import { IRegionType } from '@core/interfaces/iregion-type';
import { IMasterDomain, IMasterMeasure } from '@core/interfaces/imaster-domain';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { IDualEnrollModalData } from '@core/interfaces/idual-enroll-modal-data';
import { IExtensiveSupport } from '@core/interfaces/iextensive-support';
import { IDifficultyLevels } from '@core/interfaces/idifficulty-levels';
import { IStateDashboardTile } from '@core/interfaces/istate-dashboard';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private dataService: DataService) {}

  getCountyList(): Observable<ICounty[]> {
    return this.dataService.get(`lookup/county`);
  }

  getCountyById(id: number): Observable<ICounty> {
    return this.dataService.get(`lookup/county/${id}`);
  }

  getCountyByStateId(stateId: number): Observable<ICounty[]> {
    return this.dataService.get(`lookup/county/state/${stateId}`);
  }

  getSiteTypes(): Observable<any> {
    return this.dataService.get('lookup/site-type');
  }

  getSiteCodeTypes(): Observable<any> {
    return this.dataService.get('lookup/site-code-type');
  }

  getVendors(): Observable<IVendor[]> {
    return this.dataService.get('lookup/vendor');
  }

  getNewsItemTypes(): Observable<any[]> {
    return this.dataService.get('lookup/news-item-types');
  }

  getAccountStatusTypes(): Observable<IStatusType[]> {
    return this.dataService.get('lookup/account-status-types');
  }

  getAgeGradeInstruments(stateId: number): Observable<IAgeGradeInstrument[]> {
    return this.dataService.get(`lookup/age-grade-instruments/${stateId}`);
  }

  getLanguages(): Observable<ILanguage[]> {
    return this.dataService.get('lookup/languages');
  }

  getDistrictsOfLiability(): Observable<IDistrictOfLiability[]> {
    return this.dataService.get('lookup/district-of-liability');
  }

  getAdaptationTypes(): Observable<IAdaptationType[]> {
    return this.dataService.get('lookup/adaptation-types');
  }

  getEnrollmentProgramTypes(): Observable<IEnrollmentProgramType[]> {
    return this.dataService.get('lookup/enrollment-program-types');
  }

  getEthnicities(stateId: number): Observable<IEthnicity[]> {
    return this.dataService.get(`lookup/ethnicities/${stateId}`);
  }

  getDisabilities(): Observable<IDisability[]> {
    return this.dataService.get('lookup/disabilities');
  }

  getGenders(stateId: number): Observable<IGender[]> {
    return this.dataService.get(`lookup/genders/${stateId}`);
  }

  getTuitionFees(): Observable<ITuitionFee[]> {
    return this.dataService.get('lookup/tuition-fees');
  }

  getAgeGradeEnrollmentFilters(): Observable<
    IAgeGradeEnrollmentProgramFilter[]
  > {
    return this.dataService.get('lookup/age-grade-enrollment-filters');
  }

  getDemographicInformation(): Observable<IDemographicInformation[]> {
    return this.dataService.get('lookup/demographic-information');
  }

  getBasicSelects(): Observable<ISelectBoolean[]> {
    return this.dataService.get('lookup/yes-no-options');
  }

  getOutcomes(): Observable<IOutcome[]> {
    return this.dataService.get('lookup/outcomes');
  }

  getInitialAssessmentToolTypeOptions(): Observable<IInitialAssessmentToolType[]> {
    return this.dataService.get('lookup/initial-assessment-tool-types');
  }

  getIepPlans(): Observable<IIepPlan[]> {
    return this.dataService.get('lookup/iep-plans');
  }

  getCurrentSchoolYear(): Observable<ISchoolYear> {
    return this.dataService.get('lookup/current-schoolyear');
  }

  getFieldTypes(): Observable<any> {
    return this.dataService.get('lookup/field-types');
  }

  getClassExitReasons(): Observable<IClassExitReason> {
    return this.dataService.get('lookup/class-exit-reasons');
  }

  getClassReleaseReasons(): Observable<IClassExitReason> {
    return this.dataService.get('lookup/class-release-reasons');
  }

  getClassDeclineReleaseReasons(): Observable<any> {
    return this.dataService.get('lookup/class-decline-release-reasons');
  }

  getSuffix(): Observable<any> {
    return this.dataService.get('lookup/suffix');
  }

  getAllSchoolYears(): Observable<any> {
    return this.dataService.get(`schoolyear/all/desc`);
  }

  getSchoolYearFromStartingYear(year: string): Observable<ISchoolYear[]> {
    return this.dataService.get(`schoolyear/starting-year/${year}`);
  }

  getHeadStart(): Observable<IHeadStart[]> {
    return this.dataService.get(`lookup/head-start`);
  }

  getRegionGroups(): Observable<IRegionGroup[]> {
    return this.dataService.get(`lookup/region-groups`);
  }

  getRegionTypes(
    stateId: number,
    pageIndex: number,
    itemsPerpage: number
  ): Observable<any> {
    return this.dataService.get(
      `lookup/region-types?StateId=${stateId}&PageIndex=${pageIndex}&ItemsPerPage=${itemsPerpage}`
    );
  }

  getNHRegionTypes(): Observable<IRegionType[]> {
    return this.dataService.get(`lookup/nh-region-types`);
  }

  getCarReasons(): Observable<any[]> {
    return this.dataService.get(`lookup/car-reasons`);
  }

  getSelpas(): Observable<ISelpa[]> {
    return this.dataService.get(`lookup/selpas`);
  }

  getCompanionDocument(id: number): Observable<ICompanionDocument> {
    return this.dataService.get(`lookup/companion-document/${id}`);
  }

  getRequestReasons(): Observable<StudentRequestReason[]> {
    return this.dataService.get(`lookup/request-reasons`);
  }

  getRequestStatusType(): Observable<RequestAction[]> {
    return this.dataService.get(`lookup/request-status-types`);
  }

  getReportTypes(): Observable<IReportType[]> {
    return this.dataService.get(`lookup/report-types`);
  }

  getReportActions(): Observable<IReportAction[]> {
    return this.dataService.get(`lookup/report-actions`);
  }

  getStatePreschool(): Observable<IStatePreschool[]> {
    return this.dataService.get(`lookup/state-preschool`);
  }
  getMasterDomains(): Observable<IMasterDomain[]> {
    return this.dataService.get(`lookup/master-domains`);
  }

  getMasterMeasures(): Observable<IMasterMeasure[]> {
    return this.dataService.get(`lookup/master-measures`);
  }

  getBaseMasterMeasures(): Observable<IBaseTemplateDomain[]> {
    return this.dataService.get(`lookup/base-master-measures`);
  }

  getDualEnrollModalData(): Observable<IDualEnrollModalData> {
    return this.dataService.get(`lookup/dual-enroll-modal-data`);
  }

  getPilotAgeGrade(): Observable<IAgeGradeInstrument[]> {
    return this.dataService.get('lookup/pilot-age-grade');
  }

  getExtensiveSupports(): Observable<IExtensiveSupport[]> {
    return this.dataService.get('lookup/extensive-support')
  }

  getDifficultyLevels(): Observable<IDifficultyLevels[]> {
    return this.dataService.get('lookup/difficulty-levels');
  }

  getStateDashboardTiles(): Observable<IStateDashboardTile[]> {
    return this.dataService.get('lookup/state-dashboard-tiles');
  }
}
